import { detect } from "detect-browser";

import { equalsIgnoringCase } from "@/common/lib/string";

const inBrowser = typeof window !== "undefined";
export const hasIntersectionObserver = inBrowser && "IntersectionObserver" in window;
export const hasMutationObserver = inBrowser && "MutationObserver" in window;
export const hasServiceWorker = inBrowser && "serviceWorker" in navigator;
export const hasNotifications = inBrowser && typeof window.Notification !== "undefined";

export const copyTextToClipboard = async (text: string) => {
  let textarea = null;

  try {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      await navigator.clipboard.writeText(text);
    } else {
      textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";
      textarea.setAttribute("readonly", "");
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      return document.execCommand("copy");
    }

    return true;
  } catch (ex) {
    console.warn("Copy to clipboard failed.", ex);
    return false;
  } finally {
    if (textarea) document.body.removeChild(textarea);
  }
};

function createScrollbarMeasure(measure: (measure: HTMLElement) => void) {
  const scrollbar = document.createElement("div");
  scrollbar.className = "modal-scrollbar-measure";
  document.body.appendChild(scrollbar);
  measure(scrollbar);
  document.body.removeChild(scrollbar);
}

export const measureScrollbar = () => {
  // use default of 20px, when failed to calculate scrollbar
  const scrollbarMeasures = { width: 20, height: 20 };
  try {
    createScrollbarMeasure(measure => {
      const rect = measure.getBoundingClientRect();
      scrollbarMeasures.width = rect.width - measure.clientWidth;
      scrollbarMeasures.height = rect.height - measure.clientHeight;
    });
  } catch {
    return scrollbarMeasures;
  }

  return scrollbarMeasures;
};

export function addEventListeners(el: EventTarget, events: string[], handler: EventListener) {
  for (let i = 0, len = events.length; i < len; i++) {
    el.addEventListener(events[i], handler);
  }
}

export function removeEventListeners(el: EventTarget, events: string[], handler: EventListener) {
  for (let i = 0, len = events.length; i < len; i++) {
    el.removeEventListener(events[i], handler);
  }
}

export function testSameOrigin(first: string, second: string) {
  const a = document.createElement("a");
  const b = document.createElement("a");

  a.href = first;
  b.href = second;

  return a.hostname === b.hostname && a.port === b.port && a.protocol === b.protocol;
}

export const KeyCodes = {
  esc: 27,
  tab: 9,
  enter: 13,
  space: 32,
  comma: 188,
  up: 38,
  left: 37,
  right: 39,
  down: 40,
  backspace: 8,
  delete: [8, 46],
  a: 65,
  colon: 186,
};

export type KeyCodeKeys = keyof typeof KeyCodes;

export const Keys: { [k in KeyCodeKeys]: string } = {
  esc: "Escape",
  tab: "Tab",
  enter: "Enter",
  space: " ",
  comma: ",",
  up: "ArrowUp",
  left: "ArrowLeft",
  right: "ArrowRight",
  down: "ArrowDown",
  backspace: "Backspace",
  delete: "Delete",
  a: "a",
  colon: ":",
};

export const isKeyboardKey = (event: KeyboardEvent, code: KeyCodeKeys): event is KeyboardEvent => {
  if (!(event instanceof KeyboardEvent)) return false;

  const keyCode = KeyCodes[code];
  if (keyCode) {
    if (Array.isArray(keyCode)) {
      if (keyCode.includes(event.keyCode)) return true;
    }
    if (event.keyCode === keyCode) {
      return true;
    }
  }
  return equalsIgnoringCase(event.key, Keys[code]);
};

const browser = detect();
export const isSafari = !!browser && browser.name === "safari";
export const isIE = !!browser && browser.name === "ie";
export const isEdge = !!browser && browser.name === "edge";
export const isApple = !!browser && (browser.os === "iOS" || browser.os === "Mac OS");
export const isWindows = !!browser
  && (browser.os === "Windows 7"
    || browser.os === "Windows 10"
    || browser.os === "Windows 3.11"
    || browser.os === "Windows 95"
    || browser.os === "Windows 98"
    || browser.os === "Windows 2000"
    || browser.os === "Windows XP"
    || browser.os === "Windows Server 2003"
    || browser.os === "Windows Vista"
    || browser.os === "Windows 8"
    || browser.os === "Windows 8.1"
    || browser.os === "Windows ME");

export const browserVersion = browser ? browser.version : null;

export function isIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
