import {
  AxShareCurrentUserInfo,
  AxShareIdentityProvider,
  AxShareSubInstanceInfo,
  EmailSettings,
  IdentityProviderResponse,
  LdapSettings,
  ResponseObject,
  ResponseObjectOf,
} from "@shared/models";

import { exec } from "@/services/api";
import { server } from "@/services/server";
import { objectToFormData } from "@/services/utils/formData";

export function setPassword(accountId: string, newPassword: string, pending: boolean) {
  const request = {
    targetUserId: accountId,
    password: newPassword,
    pending,
  };
  const formData = objectToFormData(request);
  return exec<ResponseObject>(server.post("/admin/setpassword", formData));
}

export function setEmail(accountId: string, email: string) {
  const request = {
    targetUserId: accountId,
    email,
  };
  const formData = objectToFormData(request);
  return exec<ResponseObject>(server.post("/admin/setemail", formData));
}

export function addToSaml(accountIds: string) {
  const request = {
    userIds: accountIds,
  };
  const formData = objectToFormData(request);
  return exec<ResponseObject>(server.post("/admin/addtosaml", formData));
}

export function setAccessLevel(accountIds: string, accessLevel: number) {
  const request = {
    userIds: accountIds,
    accessLevel,
  };
  const formData = objectToFormData(request);
  return exec<ResponseObject>(server.post("/admin/setaccesslevel", formData));
}

export function addAccounts(
  userEmails: string,
  useSaml: boolean,
  password: string,
  accessLevel: number,
  pending: boolean,
  isRpEnterprise: boolean,
) {
  const request = {
    userEmails,
    useSaml,
    password,
    accessLevel,
    pending,
    isRpEnterprise,
  };
  const formData = objectToFormData(request);
  return exec<ResponseObject>(server.post("/admin/addaccounts", formData));
}

export function getUserInfo() {
  return exec<AxShareCurrentUserInfo>(server.get("/admin/getcurrentuserinfo"));
}

export function removeFromSaml(userIds: string) {
  const request = {
    userIds,
  };
  const formData = objectToFormData(request);
  return exec<ResponseObject>(server.post("/admin/removefromsaml", formData));
}

export function changeUserState(userIds: string, active: boolean, isRpEnterprise?: boolean) {
  const request = {
    userIds,
    active,
    isRpEnterprise,
  };
  const formData = objectToFormData(request);
  return exec<ResponseObject>(server.post("/admin/changeuserstate", formData));
}

export function getEmailSettings() {
  return exec<EmailSettings>(server.get("/admin/getemailsettings"));
}

export function smtpConfigured() {
  return exec<ResponseObjectOf<boolean>>(server.get("/admin/smtpconfigured"));
}

export function saveEmailSettings(settings: EmailSettings) {
  const request = {
    settings,
  };
  const formData = objectToFormData(request);
  return exec<ResponseObject>(server.post("/admin/submitemail", formData));
}

export function sendTestEmail(settings: EmailSettings, recepient: string) {
  const request = {
    settings,
    recepient,
  };
  const formData = objectToFormData(request);
  return exec<ResponseObject>(server.post("/admin/sendtestemail", formData));
}

export function getSamlSettings() {
  return exec<AxShareIdentityProvider>(server.get("/admin/getsamlsettings"));
}

export function saveSamlSettings(settings: AxShareIdentityProvider, certFile: any) {
  const request = {
    provider: settings,
    loginFile: certFile,
  };
  const formData = objectToFormData(request);
  return exec<IdentityProviderResponse>(server.post("/identity/savesamlsettings", formData));
}

export function clearSaml() {
  return exec<ResponseObject>(server.post("/admin/clearsaml"));
}

export function getLdapSettings() {
  return exec<LdapSettings>(server.get("/admin/getldapsettings"));
}

export function testLdapAuthentication(settings: LdapSettings, login: string, password: string, certFile: any) {
  const request = {
    settings,
    login,
    password,
    certFile,
  };
  const formData = objectToFormData(request);
  return exec<ResponseObject>(server.post("/admin/testldapauthentication", formData));
}

export function saveLdapSettings(settings: LdapSettings, certFile: any) {
  const request = {
    settings,
    certFile,
  };
  const formData = objectToFormData(request);
  return exec<ResponseObject>(server.post("/admin/submitldap", formData));
}

export function getSubInstanceInfo() {
  return exec<AxShareSubInstanceInfo>(server.get("/admin/getsubinstanceinfo"));
}

export function getLicenseKey() {
  return exec<ResponseObject>(server.get("/admin/getlicensekey"));
}

export function setLicenseKey(licenseKey: string) {
  const request = {
    licenseKey,
  };
  const formData = objectToFormData(request);
  return exec<ResponseObject>(server.post("/admin/setlicensekey", formData));
}
