import {
  FilesystemNodeShortcut, FilesystemNodeTypes, isShortcutNode, ShortcutState,
} from "@/common/fs";
import { ShortcutType } from "@/services/models";

const sketchProjectTypes = [ShortcutType.Expo, ShortcutType.Handoff];
const rpProjectTypes = [ShortcutType.Rp, ShortcutType.Lib, ShortcutType.TeamLib, ShortcutType.TeamRp];
const teamProjectTypes = [ShortcutType.TeamLib, ShortcutType.TeamRp];

export function isRp6ProjectAndBelow(options: {
  project?: FilesystemNodeShortcut;
  resourceDirVersion?: number;
  shortcutState?: string
}) {
  const { project, resourceDirVersion, shortcutState } = options;
  const state = (project && project.shortcutState) || shortcutState;
  if (state
    && (state === ShortcutState.Generating
      || state === ShortcutState.Empty
      || state === ShortcutState.GenerationFailed)) {
    return false;
  }
  const revNumber = (project && project.listingNode && project.listingNode.ResourceDirVersion) || resourceDirVersion;
  if (!revNumber) return false;
  return revNumber < MinRP7Build;
}

const MinRP7Build = 3100;
export function isRp7Project(options: { project?: FilesystemNodeShortcut; resourceDirVersion?: number }) {
  const { project, resourceDirVersion } = options;
  const revNumber = (project && project.listingNode && project.listingNode.ResourceDirVersion) || resourceDirVersion;
  if (!revNumber) return false;
  return revNumber < 10000 && revNumber >= MinRP7Build && revNumber < MinRP8Build;
}

const MinRP8Build = 3200;
export function isRp8Project(options: { project?: FilesystemNodeShortcut; resourceDirVersion?: number }) {
  const { project, resourceDirVersion } = options;
  const revNumber = (project && project.listingNode && project.listingNode.ResourceDirVersion) || resourceDirVersion;
  if (!revNumber) return false;
  return revNumber < 10000 && revNumber >= MinRP8Build && revNumber < MinRP9Build;
}

const MinRP9Build = 3600;
export function isRp9Project(options: { project?: FilesystemNodeShortcut; resourceDirVersion?: number }) {
  const { project, resourceDirVersion } = options;
  const revNumber = (project && project.listingNode && project.listingNode.ResourceDirVersion) || resourceDirVersion;
  if (!revNumber) return false;
  return revNumber < 10000 && revNumber >= MinRP9Build && revNumber < MinRP10Build;
}

const MinRP9BuildWithThumbnails = 3629;
const MinRP9HgRevWithThumbnails = 17532;
export function isRp9ProjectWithThumbnails(options: { project?: FilesystemNodeShortcut; resourceDirVersion?: number }) {
  const { project, resourceDirVersion } = options;
  const revNumber = (project && project.listingNode && project.listingNode.ResourceDirVersion) || resourceDirVersion;
  if (!revNumber) return false;
  return ((revNumber >= MinRP9HgRevWithThumbnails && revNumber < MinRP10HgRev)
    || (revNumber < 10000 && revNumber >= MinRP9BuildWithThumbnails && revNumber < MinRP10Build));
}

const MinRP10Build = 3800;
const MinRP10HgRev = 21000;
export function isRp10Project(options: { project?: FilesystemNodeShortcut; resourceDirVersion?: number }) {
  const { project, resourceDirVersion } = options;
  const revNumber = (project && project.listingNode && project.listingNode.ResourceDirVersion) || resourceDirVersion;
  if (!revNumber) return false;
  return (revNumber >= MinRP10HgRev || (revNumber < 10000 && revNumber >= MinRP10Build));
}

export function isThumbnailSupportedProject(project: FilesystemNodeTypes) {
  return isShortcutNode(project)
    && (!!project.shortcutState && project.shortcutState !== "GenerationFailed")
    // eslint-disable-next-line max-len
    && (isSketchProjectType(project.shortcutType) || isRp9ProjectWithThumbnails({ project }) || isRp10Project({ project }));
}

export function isPublicInspectSupportedProject(project: FilesystemNodeTypes) {
  return isSketchProject(project) || (isRpProject(project) && isRp10Project({ project }));
}

export function isSketchProject(project: FilesystemNodeTypes): project is FilesystemNodeShortcut {
  return isShortcutNode(project) && isSketchProjectType(project.shortcutType);
}
export function isRpProject(project: FilesystemNodeTypes): project is FilesystemNodeShortcut {
  return isShortcutNode(project) && isRpProjectType(project.shortcutType);
}
export function isTeamProject(project: FilesystemNodeTypes): project is FilesystemNodeShortcut {
  return isShortcutNode(project) && isTeamProjectType(project.shortcutType);
}
export function isSketchProjectType(type: ShortcutType) {
  return sketchProjectTypes.some(sketchType => sketchType === type);
}
export function isRpProjectType(type: ShortcutType) {
  return rpProjectTypes.some(rpType => rpType === type);
}
export function isTeamProjectType(type: ShortcutType) {
  return teamProjectTypes.some(rpType => rpType === type);
}

const RP_WITH_QUERY_SUPPORT = 3891;
const RP_TEST_WITH_QUERY_SUPPORT = 21000;
export function isQuerySupportProject(project: FilesystemNodeTypes) {
  if (!isShortcutNode(project)) return false;
  if (isSketchProjectType(project.shortcutType)) return true;
  const revNumber = (project && project.listingNode && project.listingNode.ResourceDirVersion);
  if (!revNumber) return false;
  return (revNumber >= RP_TEST_WITH_QUERY_SUPPORT || (revNumber < 10000 && revNumber >= RP_WITH_QUERY_SUPPORT));
}
